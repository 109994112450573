<template>
  <div class="special-color-dark">
    <div class="container">
      <div class="container mt-5 pt-5 text-center">
        <h1 class="font-weight-bold red-1">
          En Cercas y Mallas Versátiles ponemos a su consideración nuestra gama
          de productos de madera y maderaplas:
        </h1>
        <h5 class="gris-3 mt-4 pt-2">
          <strong>- Cercas y mallas versatiles a su servicio desde 1975 -</strong>
        </h5>
      </div>
      <div class="container mx-2 mt-5">
        <mdb-card data-aos="fade-up" class="my-card hoverable">
          <mdb-card-body>
            <h4 class="mb-3 mt-1 white-text">
              <strong>DECKS Y PISOS DE MADERA Y MADERAPLAS</strong>
            </h4>
            <hr class="my-hr">
            <p class="white-text">
              Es una línea de productos conformada por Mobiliario Urbano para
              exteriores, dichos productos son ecológicos, con alta durabilidad
              y resistentes al intemperismo.
            </p>
            <p class="white-text">
              La aplicación de los productos puede ser encasa, plaza comercial,
              empresas,ranchos, industrias, playas, etc.
            </p>
            <div class="container container-fluid pt-3">
              <div class="row mx-auto text-center">
                <div class="col-md-6 mb-4">
                  <img
                    src="../assets/madera/madera01.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="col-md-6 mb-4">
                  <img
                    src="../assets/madera/madera002.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
              </div>
              <div class="mx-auto row">
                <div class="mx-auto col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera003.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="mx-auto col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera004.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="mx-auto col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera005.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-2 mb-2">
              <a target="_blank" style="color: #fff;" @click="modal = true"><mdb-btn color="success-color-dark" class="success-color-dark">Especificaciones<mdb-icon icon="angle-up" class="ml-2" /></mdb-btn></a>
          </div>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="container mx-2 mt-5">
        <mdb-card data-aos="fade-up" class="my-card hoverable">
          <mdb-card-body>
            <h4 class="white-text">
              <strong>PÉRGOLAS Y TECHUMBRES DE MADERA Y MADERAPLAS</strong>
            </h4>
            <hr class="my-hr">
            <strong><p class="white-text">Durabilidad:</p></strong>
            <ul class="white-text">
              <li>
                Por la calidad de la materia prima y el proceso de fabricación,
                el mobiliario cuenta con excelentes propiedades mecánicas,
                físicas y químicas, manteniendo su color y propiedades estéticas
                durante un tiempo prolongado.
              </li>
              <li>
                Los productos se caracterizan por su alta resistencia a la
                lluvia ácida y agentes externos.
              </li>
              <li>
                El color de los productos está integrada así mismo cuenta con
                protección UV, de alta durabilidad al clima.
              </li>
              <li>
                Es una línea de productos conformada por Mobiliario Urbano para
                exteriores, dichos productos son ecológicos, con alta
                durabilidad y resistencia.
              </li>
              <li>
                La mayor parte del Mobiliario es fácil de manipular e instalar,
                además es más práctica la inserción de los elementos de
                sujeción.
              </li>
            </ul>
            <div class="container text-center">
              <div class="row">
                <div class="col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera007.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera008.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera009.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-6 mb-4">
                  <img
                    src="../assets/madera/madera010.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="col-md-6 mb-4">
                  <img
                    src="../assets/madera/madera011.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-2 mb-2">
              <a target="_blank" style="color: #fff;" @click="modal2 = true"><mdb-btn color="success-color-dark" class="success-color-dark">Especificaciones<mdb-icon icon="angle-up" class="ml-2" /></mdb-btn></a>
          </div>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="container mt-5 mx-2">
        <mdb-card data-aos="fade-up" class="my-card hoverable">
          <mdb-card-body>
            <h4 class="white-text"><strong>FACHADAS DE MADERA Y MADERAPLAS</strong></h4>
            <hr class="my-hr">
            <strong><p class="white-text">Ecológico:</p></strong>
            <p class="white-text">
              El Mobiliario exterior está fabricado con polietileno reciclado,
              lo que le infiere a los productos propiedades de resistencia y
              durabilidad adecuadas para su implementación.
            </p>
            <p class="white-text">
              - Los productos de MADERAPLAS son elementos plásticos 100%
              reciclados y reciclables.
            </p>
            <div class="container text-center">
              <div class="row text-center mx-auto">
                <div class="col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera012.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera013.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera014.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
              </div>
              <div class="row mt-4 text-center mx-auto">
                <div class="col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera015.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera016.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
                <div class="col-md-4 mb-4">
                  <img
                    src="../assets/madera/madera017.jpg"
                    alt=""
                    class="img-fluid z-depth-3"
                  />
                </div>
              </div>
            </div>
            <div class="text-center mt-2 mb-2">
              <a target="_blank" style="color: #fff;" @click="modal4 = true"><mdb-btn color="success-color-dark" class="success-color-dark">Especificaciones<mdb-icon icon="angle-up" class="ml-2" /></mdb-btn></a>
          </div>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="container mx-2 mt-5">
        <mdb-card data-aos="fade-up" class="my-card hoverable">
          <mdb-card-body>
            <h4 class="white-text"><strong>CERCAS DE MADERA Y MADERAPLAS</strong></h4>
            <hr class="my-hr">
            <strong><p class="white-text">Impermeabilidad:</p></strong>
            <p class="white-text">
              - El mobiliario es 100% impermeable y sintético, es por ello que
              no permite la formación de hongos, bacterias, salitre, entre
              otros. Al ser libre de absorción de agua, evita la corrosión y/o
              degradación de la estructura Y de soporte
            </p>
            <div class="row mt-4 text-center mx-auto">
              <div class="col-md-4 mb-4">
                <img
                  src="../assets/madera/madera018.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                />
              </div>
              <div class="col-md-4 mb-4">
                <img
                  src="../assets/madera/madera019.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                />
              </div>
              <div class="col-md-4 mb-4">
                <img
                  src="../assets/madera/madera020.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                />
              </div>
            </div>
            <div class="row mt-4 text-center mx-auto">
              <div class="col-md-4 mb-4">
                <img
                  src="../assets/madera/madera021.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                />
              </div>
              <div class="col-md-4 mb-4">
                <img
                  src="../assets/madera/madera022.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                />
              </div>
              <div class="col-md-4 mb-4">
                <img
                  src="../assets/madera/madera023.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                />
              </div>
            </div>
            <div class="text-center mt-2 mb-2">
              <a target="_blank" style="color: #fff;" @click="modal3 = true"><mdb-btn color="success-color-dark" class="success-color-dark">Especificaciones<mdb-icon icon="angle-up" class="ml-2" /></mdb-btn></a>
          </div>
          </mdb-card-body>
        </mdb-card>
      </div>
      <div class="container mt-5 mx-2 pb-5">
        <mdb-card data-aos="fade-up" class="my-card hoverable">
          <mdb-card-body>
            <h4 class="white-text"><strong>TEJAS MADERAPLAS</strong></h4>
            <hr class="my-hr">
            <h5 class="text-center white-text"><strong>Tejas de Ala</strong></h5>
            <div class="container mb-3">
              <div class="row text-center mx-auto">
                <div class="col-md-4 mx-auto mb-4"><img
                  src="../assets/madera/madera024.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                /></div>
                <div class="col-md-4 mx-auto mb-4"><img
                  src="../assets/madera/madera025.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                /></div>
              </div>
            </div>
            <h5 class="text-center white-text"><strong>Tejas Flat</strong></h5>
            <div class="container mb-4">
              <div class="row">
                <div class="col-md-4 mx-auto mb-4"><img
                  src="../assets/madera/madera026.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                /></div>
                <div class="col-md-4 mx-auto mb-4"><img
                  src="../assets/madera/madera027.jpg"
                  alt=""
                  class="img-fluid z-depth-3"
                /></div>
              </div>
            </div>
            <div class="text-center mt-2 mb-2">
              <a target="_blank" style="color: #fff;" @click="modal5 = true"><mdb-btn color="success-color-dark" class="success-color-dark">Especificaciones<mdb-icon icon="angle-up" class="ml-2" /></mdb-btn></a>
          </div>
          </mdb-card-body>
        </mdb-card>
      </div>
    </div>
        <a target="_blank" href="https://api.whatsapp.com/message/OM43BU5OG5GNE1"
      ><mdb-btn
        color="succes"
        class="gradient-red-2 text-white"
        rounded
        id="fixedbutton"
        ><strong
          ><p class="mb-0">¡Presupuesto</p>
          gratis!</strong
        ></mdb-btn
      ></a
    >


 <!-- modales -->
    <div>
    <mdb-modal :show="modal" @close="modal = false" centered>
      <mdb-modal-header class="amber">
        <mdb-modal-title>Pisos de madera</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>Pisos en madera con texturas, tamaños y colores exclusivos que proporcionan una
apariencia única a sus espacios, realzando de modos distintos la naturalidad de la
madera.Todos nuestros pisos son de la más alta calidad.</mdb-modal-body>
      <mdb-modal-footer>
        <a target="_blank" class="mx-auto" href="https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20Pisos%20de%20madera"><mdb-btn color="success" class="mx-auto" @click.native="modal = false">Preguntar por el servicio<mdb-icon icon="hammer" class="ml-2"/></mdb-btn></a>
      </mdb-modal-footer>
    </mdb-modal>
  </div>

  <div>
    <mdb-modal :show="modal2" @close="modal2 = false" centered>
      <mdb-modal-header class="amber">
        <mdb-modal-title>Pérgolas</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>Su pérgola de madera ofrecerá belleza y carácter, dará un toque orgánico y rústico además
de agregar carácter a cualquier espacio, no importa su tamaño.
Sus elementos orgánicos acompañan perfecto terrazas y jardines y por supuesto que van
de la mano de los roof gardens.</mdb-modal-body>
      <mdb-modal-footer>
        <a target="_blank" class="mx-auto" href="https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20Pergolas"><mdb-btn class="mx-auto" color="success" @click.native="modal2 = false">Preguntar por el servicio<mdb-icon icon="hammer" class="ml-2"/></mdb-btn></a>
      </mdb-modal-footer>
    </mdb-modal>
  </div>

  <div>
    <mdb-modal :show="modal4" @close="modal4 = false" centered>
      <mdb-modal-header class="amber">
        <mdb-modal-title>Fachadas de madera</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>La madera es un aislante térmico y acústico natural. Tiene una apariencia natural y
adaptabilidad a cualquier estilo arquitectónico y/o decorativo. Además se puede combinar
fácilmente con otros materiales como el vidrio, hormigón o piedra.
Aunque existen diferentes tipos de fachadas de madera todas ellas deben respetar la
naturaleza del material para conseguir un resultado duradero.</mdb-modal-body>
      <mdb-modal-footer>
        <a target="_blank" class="mx-auto" href="https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20Fachadas%20de%20madera"><mdb-btn class="mx-auto" color="success" @click.native="modal4 = false">Preguntar por el servicio<mdb-icon icon="hammer" class="ml-2"/></mdb-btn></a>
      </mdb-modal-footer>
    </mdb-modal>
  </div>

   <div>
    <mdb-modal :show="modal3" @close="modal3 = false" centered>
      <mdb-modal-header class="amber">
        <mdb-modal-title>Cercas de madera</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>Lo mejor de las cercas de madera es que harán que tu hogar luz muy natural y fresco,
convirtiéndolo en un hermoso santuario personal.
Sea tu casa, tu jardín, o incluso tu terraza, cualquier lugar lucirá mejor con una cerca de
madera.</mdb-modal-body>
      <mdb-modal-footer>
        <a target="_blank" class="mx-auto" href="https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20Cercas%20de%20madera"><mdb-btn class="mx-auto" color="success" @click.native="modal3 = false">Preguntar por el servicio<mdb-icon icon="hammer" class="ml-2"/></mdb-btn></a>
      </mdb-modal-footer>
    </mdb-modal>
  </div>

   <div>
    <mdb-modal :show="modal5" @close="modal5 = false" centered>
      <mdb-modal-header class="amber">
        <mdb-modal-title>Tejas</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>Las tejas son una parte muy importante en el diseño y creación de fachadas, las tejas
aportan un estilo único e inigualable. Decora, protege y da valor a tu construcción.</mdb-modal-body>
      <mdb-modal-footer>
        <a target="_blank" class="mx-auto" href="https://api.whatsapp.com/send?phone=5212293060014&text=Quiero%20hacer%20una%20cotizaci%C3%B3n%20de%20Tejas"><mdb-btn class="mx-auto" color="success" @click.native="modal5 = false">Preguntar por el servicio<mdb-icon icon="hammer" class="ml-2"/></mdb-btn></a>
      </mdb-modal-footer>
    </mdb-modal>
  </div>


  </div>
</template>

<script>
import { mdbCard, mdbCardBody, mdbBtn, mdbIcon, mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, } from "mdbvue";
export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbBtn,
    mdbIcon,
    mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter,
  },
  data() {
    return {
      modal: false,
      modal2: false,
      modal3: false,
      modal4: false,
      modal5: false,
    }
},
  mounted() {
    document.title = 'Cercas y Mallas Versatiles - Maderaplas'
  },
};
</script>

<style>
.back-3 {
    background-color: #696868ee;
}
#fixedbutton {
  position: fixed;
  bottom: 0px;
  right: 0px;
  margin-right: 30px;
  margin-bottom: 25px;
  z-index: 999;
}
.red-1 {
  color: rgb(233, 0, 0);
  text-shadow: 2px 2px 8px #e93939;
  -webkit-text-stroke: 1px #ffff;
}
.gris-3 {
  color: #d8d8d8;
}
.my-card {
background:linear-gradient(0deg, rgba(0, 0, 0, 0.250), rgba(0, 0, 0, 0.250)), url('../assets/madera0.jpg') center no-repeat;
background-size: cover;
}
.my-hr {
  border-top: 1px dashed rgb(207, 207, 207);
  border-radius: 5px;
}
</style>